import { ProjectLanguage, typedObjectKeys } from "@hex/common";
import React from "react";

import { HexButton } from "../../../hex-components/HexButton.js";
import {
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
} from "../../../hex-components/HexMenuItem.js";
import { HexPopover } from "../../../hex-components/HexPopover.js";
import {
  LocalStorageKeys,
  useLocalStorage,
} from "../../../hooks/useLocalStorage.js";
import { useProjectContext } from "../../../util/projectContext.js";
import { useHexFlag } from "../../../util/useHexFlags.js";
import {
  FilterCellIcon,
  SearchIcon,
  SingleChevronDownIcon,
  SortAlphaHorizontalIcon,
} from "../../icons/CustomIcons.js";
import { CellFilterShowType } from "../constants.js";

interface FilterProjectCellsProps {
  includeSearchModeSelection?: boolean;
}
/**
 * Component that allows us to filter project cells in a sidebar tab (like the project search or outline tabs)
 */
export const FilterProjectCells: React.ComponentType<FilterProjectCellsProps> =
  React.memo<FilterProjectCellsProps>(function FilterProjectCells({
    includeSearchModeSelection,
  }) {
    const projectReplace = useHexFlag("project-find-and-replace");
    const [outlineShowType, setOutlineShowType] = useLocalStorage(
      LocalStorageKeys.CELL_FILTER_SHOW_TYPE,
    );
    const [replaceMode, setReplaceMode] = useLocalStorage(
      LocalStorageKeys.PROJECT_REPLACE_OPEN,
    );
    const { language } = useProjectContext();

    return (
      <HexPopover
        content={
          <>
            {includeSearchModeSelection && projectReplace && (
              <>
                <HexMenu>
                  <HexMenuItem
                    active={!replaceMode}
                    icon={<SearchIcon />}
                    text="Find"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setReplaceMode(false)}
                  />
                  <HexMenuItem
                    active={replaceMode}
                    icon={<SortAlphaHorizontalIcon />}
                    text="Replace"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setReplaceMode(true)}
                  />
                </HexMenu>
                <HexMenuDivider />
              </>
            )}
            <HexMenu>
              {typedObjectKeys(CellFilterShowType)
                .filter((key) => {
                  if (
                    key === "Input" ||
                    key === "Visualizations" ||
                    key === "Transformations"
                  ) {
                    return language === ProjectLanguage.PYTHON;
                  }
                  return true;
                })
                .map((key) => {
                  const cellType = CellFilterShowType[key];
                  const isActive = outlineShowType.label === cellType.label;
                  return (
                    <HexMenuItem
                      key={key}
                      active={isActive}
                      icon={isActive ? <FilterCellIcon /> : null}
                      text={cellType.display}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => setOutlineShowType(cellType)}
                    />
                  );
                })}
            </HexMenu>
          </>
        }
        minimal={true}
        placement="bottom-end"
      >
        <HexButton
          rightIcon={<SingleChevronDownIcon iconSize={12} />}
          small={true}
          subtle={true}
          text={outlineShowType.display}
        />
      </HexPopover>
    );
  });
