import {
  FilledDynamicValueTableColumnType,
  SharedFilterInputType,
  SharedFilterPredicateOp,
} from "@hex/common";

export const sharedFilterColumnTypeToDefaultOperator: Record<
  FilledDynamicValueTableColumnType,
  SharedFilterPredicateOp
> = {
  STRING: "IS_ONE_OF",
  NUMBER: "EQ",
  UNKNOWN: "EQ",
  DATETIME: "DATE_BETWEEN",
  DATETIMETZ: "DATE_BETWEEN",
  DATE: "DATE_BETWEEN",
  TIME: "DATE_BETWEEN",
  BOOLEAN: "IS_TRUE",
};

export const sharedFilterOperatorToDefaultInputType: Record<
  SharedFilterPredicateOp,
  SharedFilterInputType
> = {
  // unary
  IS_TRUE: "CHECKBOX",
  IS_FALSE: "CHECKBOX",
  ALWAYS: "CHECKBOX",
  IS_NULL: "CHECKBOX",
  NOT_NULL: "CHECKBOX",
  // binary
  GT: "NUMERIC_INPUT",
  GTE: "NUMERIC_INPUT",
  LTE: "NUMERIC_INPUT",
  LT: "NUMERIC_INPUT",
  EQ: "DROPDOWN",
  NEQ: "DROPDOWN",
  CONTAINS: "TEXT_INPUT",
  NOT_CONTAINS: "TEXT_INPUT",
  DATE_BEFORE: "DATE",
  DATE_EQUAL_OR_BEFORE: "DATE",
  DATE_EQUAL: "DATE",
  DATE_AFTER: "DATE",
  DATE_EQUAL_OR_AFTER: "DATE",
  // list binary
  IS_ONE_OF: "MULTISELECT",
  NOT_ONE_OF: "MULTISELECT",
  DATE_BETWEEN: "DATE",
};

export const sharedFilterOperatorToValidInputTypes: Record<
  SharedFilterPredicateOp,
  SharedFilterInputType[]
> = {
  // unary
  IS_TRUE: ["CHECKBOX"],
  IS_FALSE: ["CHECKBOX"],
  ALWAYS: ["CHECKBOX"],
  IS_NULL: ["CHECKBOX"],
  NOT_NULL: ["CHECKBOX"],
  // binary numeric
  GT: ["TEXT_INPUT", "NUMERIC_INPUT", "SLIDER"],
  GTE: ["TEXT_INPUT", "NUMERIC_INPUT", "SLIDER"],
  LTE: ["TEXT_INPUT", "NUMERIC_INPUT", "SLIDER"],
  LT: ["TEXT_INPUT", "NUMERIC_INPUT", "SLIDER"],
  // binary text
  EQ: ["DROPDOWN", "TEXT_INPUT"],
  NEQ: ["DROPDOWN", "TEXT_INPUT"],
  CONTAINS: ["TEXT_INPUT"],
  NOT_CONTAINS: ["TEXT_INPUT"],
  // binary date
  DATE_BEFORE: ["DATE"],
  DATE_EQUAL_OR_BEFORE: ["DATE"],
  DATE_EQUAL: ["DATE"],
  DATE_AFTER: ["DATE"],
  DATE_EQUAL_OR_AFTER: ["DATE"],
  // list binary
  IS_ONE_OF: ["MULTISELECT"],
  NOT_ONE_OF: ["MULTISELECT"],
  DATE_BETWEEN: ["DATE"],
};
