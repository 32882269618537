import {
  CellId,
  CellType,
  HexVersionAtomicOperation,
  UPDATE_CODE_CELL,
  UPDATE_MARKDOWN_CELL,
  UPDATE_SQL_CELL,
} from "@hex/common";
import { Literal, Static, Union } from "runtypes";

import { CellContentsMP } from "../../../redux/slices/hexVersionMPSlice.js";
export const REGEX_GLOBAL_ALL_MATCHES = "gmi";
export const REGEX_GLOBAL_CAPS_MATCHES = "gm";
/**
 * All of the types of searchable items that we can find in a project now.
 */
export const SearchItemTypeLiteral = Union(
  Literal("PROJECT_DESCRIPTION"),
  Literal("PROJECT_TITLE"),
  /**
   * A searchable term within a code cell. We use this so that we can index into the actual cell. This includes
   * any cell type with text and line numbers.
   */
  Literal("CELL_LINE"),
  /**
   * An output of a cell.
   */
  Literal("CELL_OUTPUT"),
  /**
   * An input paramter for a cell. Not all cells will have input parameters, and some may have several.
   */
  Literal("CELL_INPUT"),
  /**
   * The cell's label.
   */
  Literal("CELL_LABEL"),
);

type PROJECT_METADATA = "PROJECT_DESCRIPTION" | "PROJECT_TITLE";

export type SearchableItemType = Static<typeof SearchItemTypeLiteral>;
type BaseSearchableItem = {
  /**
   * Used for headers.
   */
  cellLabel: string;
  /**
   * Used to group each set of matches into the search results.
   */
  groupById: PROJECT_METADATA | CellId;
  /**
   * The type of the searchable item, which can be used for rendering
   * or when deciding if we should replace the item with a new value
   * for find and replace.
   */
  type: SearchableItemType;
  /**
   * specific cell properties. All cells will have a type, label, and order, and some cells (text/sql/code)
   * will also have a line index.
   */
  lineIndex: number | null;
  /**
   * the initial source string that we are searching in.
   */
  lineSource: string;
  /**
   * The searachable item's start and end index into the `source` string.
   */
  match?: {
    startIndex: number;
    endIndex: number;
  };
};

type ReplaceMapConfig = {
  [K in SearchableItemType]?: {
    updateSourceCallback?: (
      props: UpdateSourceCallbackProps,
    ) => HexVersionAtomicOperation | null;
  };
};
type ReplaceableItemConfig = { [K in CellType]?: ReplaceMapConfig };

/**
 * Replaces all instances of a substring in a source string with a new substring.
 */
function replaceAllForSource({
  caseMatch,
  currentSubstring,
  exactWordMatch,
  replaceWith,
  sourceString,
}: {
  sourceString: string;
  currentSubstring: string;
  replaceWith: string;
  caseMatch: boolean;
  exactWordMatch: boolean;
}): string {
  const pattern = cleanSearchTerm(currentSubstring, exactWordMatch);
  // Create the regex flags
  const flags = caseMatch
    ? REGEX_GLOBAL_CAPS_MATCHES
    : REGEX_GLOBAL_ALL_MATCHES;

  // Create the regex
  const regex = new RegExp(pattern, flags);

  // Replace using the regex
  return sourceString.replace(regex, replaceWith);
}

export function isReplaceable(item: SearchableItem): boolean {
  return (
    item.cellType != null &&
    PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP[item.cellType]?.[item.type] != null
  );
}

export function getKeyForReplaceItems(item: SearchableItem): string {
  return `${item.cellId}-${item.type}`;
}

export interface ReplaceItemOperationProps extends CellItem {
  cellContents?: CellContentsMP;
  replaceString: string;
  replaceWith: string;
  caseMatch: boolean;
  exactWordMatch: boolean;
}

export interface UpdateSourceCallbackProps {
  cellId: CellId;
  cellContents: CellContentsMP;
  nextSource: string;
}

/**
 * Sentinel value that can be used to indicate a cell search item is not replaceable.
 */
export const SENTINEL_NO_MP_REPLACE_OPERATION = "NO_OP_MP_OPERATION";

/**
 * This is a mapping from replaceable cell types with the contents that can be replaced.
 * Note that the mapping replaceCallback only support 'Replace all' behavior right now.
 */
export const PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP: ReplaceableItemConfig = {
  CODE: {
    CELL_LINE: {
      updateSourceCallback: ({
        cellContents,
        cellId,
        nextSource,
      }: UpdateSourceCallbackProps) => {
        return cellContents.__typename === "CodeCell"
          ? UPDATE_CODE_CELL.create({
              key: "source",
              value: nextSource,
              cellId,
              codeCellId: cellContents.codeCellId,
            })
          : null;
      },
    },
  },
  SQL: {
    CELL_LINE: {
      updateSourceCallback: ({
        cellContents,
        cellId,
        nextSource,
      }: UpdateSourceCallbackProps) => {
        return cellContents.__typename === "SqlCell"
          ? UPDATE_SQL_CELL.create({
              key: "source",
              value: nextSource,
              cellId,
              sqlCellId: cellContents.sqlCellId,
            })
          : null;
      },
    },
  },
  MARKDOWN: {
    CELL_LINE: {
      updateSourceCallback: ({
        cellContents,
        cellId,
        nextSource,
      }: UpdateSourceCallbackProps) => {
        return cellContents.__typename === "MarkdownCell"
          ? UPDATE_MARKDOWN_CELL.create({
              key: "source",
              value: nextSource,
              cellId,
              markdownCellId: cellContents.markdownCellId,
            })
          : null;
      },
    },
  },
};

export type CellItem = BaseSearchableItem & {
  type: SearchableItemType;
  cellId: CellId;
  cellType: CellType;
  cellOrder: string;
};

export type ProjectMetadataItem = BaseSearchableItem & {
  type: PROJECT_METADATA;
  cellId?: null;
  cellType?: null;
  cellOrder?: null;
};

/**
 * Util function to make sure that we are always splitting cell source lines in the same way.
 */
export function sourceToLines(source: string): string[] {
  return source.split(/\r?\n/);
}
export function linesToSource(lines: string[]): string {
  return lines.join("\n");
}
/**
 * Cleans the search term of any special characters that would be used in a regex.
 */
export function cleanSearchTerm(
  searchTerm: string,
  exactWordMatch: boolean,
): string {
  const cleanedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return exactWordMatch ? `\\b${cleanedTerm}\\b` : cleanedTerm;
}

/**
 * Util function to use the type that we use for grouping different project search results with
 * a specific header.
 */
export const getProjectSearchResultType = (
  item: SearchableItem,
): CellType | "ProjectMatch" => {
  if (item.cellType) {
    return item.cellType;
  }
  return "ProjectMatch";
};

export type SearchableItem = ProjectMetadataItem | CellItem;
/**
 * A searchableItem with a listOffset, which specifies where the item is in a search list.
 */
export type IndexedSearchItem = SearchableItem & {
  readonly listOffset: number;
};

/*
 * This function will replace the source line item with the replaceString.
 * It is intended to only support raw string replacement for Monaco cells or raw text cells.
 */
export function replaceSourceLineItem(
  item: CellItem,
  cellContents: CellContentsMP,
  replaceString: string,
): HexVersionAtomicOperation | null {
  if (
    !isReplaceable(item) ||
    item.lineIndex == null ||
    item.match == null ||
    !("source" in cellContents)
  ) {
    return null;
  }

  const sourceLines = sourceToLines(cellContents.source);
  if (sourceLines.length <= item.lineIndex) {
    return null;
  }
  const line = sourceLines[item.lineIndex];
  sourceLines[item.lineIndex] =
    line.substring(0, item.match.startIndex) +
    replaceString +
    line.substring(item.match.endIndex);

  const replaceItemConfig =
    PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP[item.cellType];

  if (replaceItemConfig && replaceItemConfig[item.type]) {
    const replaceItemConfigForType = replaceItemConfig[item.type];
    if (replaceItemConfigForType) {
      return (
        replaceItemConfigForType.updateSourceCallback?.({
          cellContents,
          cellId: item.cellId,
          nextSource: linesToSource(sourceLines),
        }) ?? null
      );
    }
  }
  return null;
}

export function replaceAllForItemSource({
  caseMatch,
  cellContents,
  cellId,
  cellType,
  exactWordMatch,
  replaceString,
  replaceWith,
  type,
}: ReplaceItemOperationProps): HexVersionAtomicOperation | null {
  const replaceItemConfig = PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP[cellType];
  if (
    cellContents &&
    "source" in cellContents &&
    replaceItemConfig &&
    replaceItemConfig[type]
  ) {
    const nextSource = replaceAllForSource({
      sourceString: cellContents.source,
      currentSubstring: replaceString,
      replaceWith,
      caseMatch,
      exactWordMatch,
    });

    const replaceItemConfigForType = replaceItemConfig[type];
    if (replaceItemConfigForType) {
      return (
        replaceItemConfigForType.updateSourceCallback?.({
          cellContents,
          cellId: cellId,
          nextSource,
        }) ?? null
      );
    }
  }
  return null;
}
