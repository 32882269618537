import { Collapse } from "@blueprintjs/core";
import { CellType } from "@hex/common";
import React, { useState } from "react";

import { MatchHeader } from "./MatchHeader";
import { IndexedSearchItem } from "./utils.js";

export interface ProjectSearchResultProps {
  type: CellType | "ProjectMatch";
  groupId: string;
  firstMatch: IndexedSearchItem;
  matchesForCell: IndexedSearchItem[];
  renderItem: (item: IndexedSearchItem, index: number) => void;
  countsByGroup: Record<string, number>;
}

export const ProjectSearchResult: React.ComponentType<ProjectSearchResultProps> =
  React.memo(function ProjectSearchResult({
    countsByGroup,
    firstMatch,
    groupId,
    matchesForCell,
    renderItem,
    type,
  }: ProjectSearchResultProps) {
    const [collapseResultCell, setResultsOpen] = useState(true);
    return (
      <span>
        <MatchHeader
          collapseCallback={setResultsOpen}
          expanded={collapseResultCell}
          label={firstMatch.cellLabel}
          matchCount={countsByGroup[groupId]}
          type={type}
        />
        <Collapse key={groupId} isOpen={collapseResultCell}>
          {matchesForCell.map((match, i) => renderItem(match, i))}
        </Collapse>
      </span>
    );
  });
