import { rgba } from "polished";
import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import { HexButton } from "../../../hex-components/HexButton.js";
import { HexTooltip } from "../../../hex-components/HexTooltip.js";
import { ChangelogIcon } from "../../icons/CustomIcons.js";

import { SearchableItem } from "./utils.js";

const SearchResultText = styled.span<{
  $isMatch?: boolean;
  $isCode?: boolean;
  $isCellLabel?: boolean;
  $isCellOutput?: boolean;
}>`
  color: ${({ theme }) => theme.fontColor.DEFAULT};

  ${({ $isCellLabel }) =>
    $isCellLabel &&
    css`
      font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
    `};

  ${({ $isCode }) =>
    $isCode &&
    css`
      font-family: ${({ theme }) => theme.fontFamily.MONO};
    `};

  ${({ $isCellOutput }) =>
    $isCellOutput &&
    css`
      background-color: ${({ theme }) => theme.pill.COBALT.backgroundColor};
      color: ${({ theme }) => theme.pill.COBALT.color};
      font-family: ${({ theme }) => theme.fontFamily.MONO};
    `};

  ${({ $isMatch }) =>
    $isMatch &&
    css`
      background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.2)};
    `};
`;

const ReplacedMatch = styled(SearchResultText)`
  text-decoration: line-through;
  background-color: ${({ theme }) => rgba(theme.intent.DANGER, 0.2)};
`;

const ReplaceValue = styled(SearchResultText)`
  background-color: ${({ theme }) => rgba(theme.intent.SUCCESS, 0.2)};
`;

const ReplaceButton = styled(HexButton)`
  cursor: pointer;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
  &:hover {
    color: ${({ theme }) => theme.highlightColor};
  }
`;

const StyledHexTooltip = styled(HexTooltip)`
  margin-left: auto;
`;

const TextMatch = styled.div`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  align-items: center;
`;

const MatchedLine = styled.div`
  padding: 5px 12px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.fontColor.MUTED};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MATCH_SEARCH_STRING_CONTEXT_LENGTH = 15;

const CellCodeDiv = styled.div<{ $active: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  ${({ $active, theme }) =>
    $active &&
    css`
      background: ${theme.activeColor};

      ${MatchedLine} {
        color: ${theme.fontColor.DEFAULT};
      }
    `}

  cursor: pointer;

  &:hover {
    ${MatchedLine} {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
      ${ReplaceButton} {
        opacity: 1;
      }
    }
  }
`;

interface HighlightedLineMatchProps {
  active: boolean;
  id: string;
  item: SearchableItem;
  handleClick: React.MouseEventHandler<HTMLElement>;
  /**
   * If we are in replace mode, then include styling with strikethrough text for the
   * search match and replace value.
   */
  replaceValue: string | null;
  /**
   * Callback method to handle a single entry replace.
   */
  handleReplaceItemCallback: (item: SearchableItem) => void;
}

export const HighlightedLineMatch: React.ComponentType<HighlightedLineMatchProps> =
  React.memo(function HighlightedLineMatch({
    active,
    handleClick,
    handleReplaceItemCallback,
    id,
    item,
    replaceValue,
  }) {
    const { lineSource: source, match } = item;

    // Props that we use for styling the search string, so that we can
    // share the styles for the match and before/after strings.
    const textMatchProps = useMemo(() => {
      return {
        $isCellLabel: item.type === "CELL_LABEL",
        $isCode:
          item.type === "CELL_LINE" && ["CODE", "SQL"].includes(item.cellType),
        $isCellOutput: item.type === "CELL_OUTPUT",
      };
    }, [item.cellType, item.type]);

    const { endString, matchString, startString } = useMemo(() => {
      if (!match) {
        return {
          endString: "",
          matchString: "",
          startString: "",
        };
      }
      // take the last MATCH_SEARCH_STRING_CONTEXT_LENGTH characters of startString
      let start = source.substring(0, match.startIndex);
      if (start.length > MATCH_SEARCH_STRING_CONTEXT_LENGTH) {
        start = `...${start.slice(-MATCH_SEARCH_STRING_CONTEXT_LENGTH)}`;
      }
      // take the first MATCH_SEARCH_STRING_CONTEXT_LENGTH characters of endString
      let end = source.substring(match.endIndex, source.length);
      if (end.length > MATCH_SEARCH_STRING_CONTEXT_LENGTH) {
        end = `${end.slice(0, MATCH_SEARCH_STRING_CONTEXT_LENGTH)}...`;
      }

      return {
        startString: start,
        matchString: source.substring(match.startIndex, match.endIndex),
        endString: end,
      };
    }, [match, source]);

    const handleReplaceSingleItem = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        // stop propagation of the outer div row click.
        e.stopPropagation();
        handleReplaceItemCallback(item);
      },
      [item, handleReplaceItemCallback],
    );

    if (!match) {
      return null;
    }

    return (
      <li id={id}>
        <CellCodeDiv $active={active} onClick={handleClick}>
          <MatchedLine>
            <TextMatch>
              <SearchResultText {...textMatchProps}>
                {startString}
              </SearchResultText>
              {replaceValue ? (
                <>
                  <ReplacedMatch>{matchString}</ReplacedMatch>
                  <ReplaceValue>{replaceValue}</ReplaceValue>
                </>
              ) : (
                <SearchResultText {...textMatchProps} $isMatch={true}>
                  {matchString}
                </SearchResultText>
              )}
              <SearchResultText {...textMatchProps}>
                {endString}
              </SearchResultText>
            </TextMatch>
            {replaceValue && (
              <StyledHexTooltip
                content="Replace"
                disabled={!active}
                hoverOpenDelay={1000}
                placement="bottom"
              >
                <ReplaceButton
                  extraSmall={true}
                  icon={<ChangelogIcon />}
                  minimal={true}
                  onClick={handleReplaceSingleItem}
                />
              </StyledHexTooltip>
            )}
          </MatchedLine>
        </CellCodeDiv>
      </li>
    );
  });
