import { rgba } from "polished";
import styled, { css } from "styled-components";

export const SidebarDivider = styled.div`
  flex: none;
  width: 100%;
  height: 1px;

  background: ${({ theme }) => theme.borderColor.MUTED};
`;
export const SidebarScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  overflow: auto;
`;

export const HOME_SIDEBAR_WIDTH = 264;
export const MINIMIZED_HOME_SIDEBAR_WIDTH = 55;
export const SIDEBAR_PADDING = 8;

export const AppSidebarContainer = styled.div<{ $showBorder: boolean }>`
  display: flex;
  flex: none;
  flex-direction: column;
  height: 100%;

  ${({ $showBorder, theme }) =>
    $showBorder &&
    css`
      width: 300px;

      background: ${theme.backgroundColor.DEFAULT};
      border-left: 1px solid ${rgba(theme.borderColor.DEFAULT, 0.5)};
    `}

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.EXTRA_SMALL}px`}) {
    width: 100%;
  }
`;
