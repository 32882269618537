import { Classes } from "@blueprintjs/core";
import { CellType } from "@hex/common";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import { HexTag } from "../../../hex-components";
import { EditableLabel } from "../../cell/shared/EditableLabel";
import {
  ChartCellIcon,
  CodeCellIcon,
  ComponentIcon,
  DbtIcon,
  ExploreIcon,
  FilterCellIcon,
  MapCellIcon,
  MarkdownCellIcon,
  PivotCellIcon,
  ProjectIcon,
  SQLCellIcon,
  SingleChevronDownIcon,
  SingleChevronRightIcon,
  SingleValueIcon,
  TableCellIcon,
  TextCellIcon,
  WritebackCellIcon,
} from "../../icons/CustomIcons";

const CountBadge = styled(HexTag)`
  text-align: center;
  &&& {
    font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
    background-color: ${({ theme }) => rgba(theme.borderColor.MUTED, 0.5)};
  }
`;

const MatchHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 5px 8px;

  svg {
    color: ${({ theme }) => theme.iconColor};
  }

  &:hover {
    cursor: pointer;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const CellLabel = styled.div`
  display: flex;
  align-items: center;

  div {
    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
  }

  /* ensures that pointer shows over ContentEditable label */
  &:hover > * {
    cursor: pointer;
  }

  .${Classes.ICON} {
    margin-right: 6px;
  }
`;

const cellIcon = (
  cellType: CellType | "ProjectMatch",
): React.ReactElement | null => {
  switch (cellType) {
    case CellType.CODE:
      return <CodeCellIcon />;
    case CellType.SQL:
      return <SQLCellIcon />;
    case CellType.WRITEBACK:
      return <WritebackCellIcon />;
    case CellType.PIVOT:
      return <PivotCellIcon />;
    case CellType.FILTER:
      return <FilterCellIcon />;
    case CellType.DBT_METRIC:
      return <DbtIcon />;
    case CellType.COMPONENT_IMPORT:
      return <ComponentIcon />;
    case CellType.TEXT:
      return <TextCellIcon />;
    case CellType.MARKDOWN:
      return <MarkdownCellIcon />;
    case CellType.DISPLAY_TABLE:
      return <TableCellIcon />;
    case CellType.VEGA_CHART:
    case CellType.CHART:
      return <ChartCellIcon />;
    case CellType.EXPLORE:
      return <ExploreIcon />;
    case CellType.METRIC:
      return <SingleValueIcon />;
    case CellType.MAP:
      return <MapCellIcon />;
    case "ProjectMatch":
      return <ProjectIcon />;
    default:
      return null;
  }
};

interface MatchHeaderProps {
  type: CellType | "ProjectMatch";
  label: string;
  matchCount: number;
  expanded: boolean;
  collapseCallback: (expanded: boolean) => void;
}

export const MatchHeader: React.ComponentType<MatchHeaderProps> = React.memo(
  function MatchHeader({
    collapseCallback,
    expanded,
    label,
    matchCount,
    type: cellType,
  }) {
    return (
      <MatchHeaderWrapper onClick={() => collapseCallback(!expanded)}>
        <LeftWrapper>
          <CellLabel>
            {cellIcon(cellType)}{" "}
            <EditableLabel editable={false} label={label} />
            <CountBadge minimal={true}>{matchCount}</CountBadge>
          </CellLabel>
        </LeftWrapper>
        {expanded ? <SingleChevronDownIcon /> : <SingleChevronRightIcon />}
      </MatchHeaderWrapper>
    );
  },
);
