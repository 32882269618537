import React, { ReactNode } from "react";
import styled from "styled-components";

import { HexButton } from "../../hex-components";
import { AddIcon } from "../icons/CustomIcons";

import { SidebarDivider } from "./sidebarSharedStyles";

const HeaderContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 11px 10px 10px 15px;
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HeaderTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Title = styled.div`
  display: flex;

  font-weight: 500;
`;

const HeaderDescription = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const AddActionButton = styled(HexButton).attrs({
  icon: <AddIcon />,
  subtle: true,
  extraSmall: true,
  text: "Add",
})``;

export interface SidebarHeaderProps {
  title: string;
  titleIcon?: ReactNode;
  icon?: ReactNode;
  description?: string | ReactNode;
  filter?: ReactNode;
  topActions?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const SidebarHeader: React.ComponentType<SidebarHeaderProps> =
  React.memo(function SidebarHeader({
    className,
    description,
    filter,
    icon,
    style,
    title,
    titleIcon,
    topActions,
  }) {
    return (
      <>
        <HeaderContainer className={className} style={style}>
          <HeaderInfo>
            <HeaderTop>
              <HeaderTitle>
                {icon}
                <Title>{title}</Title>
                {titleIcon}
              </HeaderTitle>
              <div>{topActions}</div>
            </HeaderTop>
            {description && (
              <HeaderDescription>{description}</HeaderDescription>
            )}
          </HeaderInfo>
        </HeaderContainer>
        {filter && (
          <>
            <SidebarDivider />
            {filter}
          </>
        )}
      </>
    );
  });
