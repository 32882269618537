/**
 * This is a seperate file to help with code splitting
 */

type CellFilterShowTypeKey =
  | "All"
  | "Code"
  | "Components"
  | "Input"
  | "Sql"
  | "Text"
  | "Transformations"
  | "Visualizations";

export const CellFilterShowType: Record<
  CellFilterShowTypeKey,
  { display: string; label: string; typename?: string | readonly string[] }
> = {
  All: {
    display: "All",
    label: "all",
  },
  Code: {
    display: "Code",
    label: "Code",
    typename: "CodeCell",
  },
  Components: {
    display: "Components",
    label: "Components",
    typename: "ComponentImportCell",
  },
  Input: {
    display: "Input parameters",
    label: "Input parameters",
    typename: "Parameter",
  },
  Sql: {
    display: "SQL",
    label: "SQL",
    typename: "SqlCell",
  },
  Text: {
    display: "Text + Markdown",
    label: "Text + Markdown",
    typename: ["MarkdownCell", "TextCell"],
  },
  Transformations: {
    display: "Transformations",
    label: "Transformations",
    typename: ["FilterCell", "PivotCell"],
  },
  Visualizations: {
    display: "Visualizations",
    label: "Visualizations",
    typename: ["ChartCell", "DisplayTableCell", "MetricCell", "MapCell"],
  },
};
